











































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UseInfo extends Vue {
  @Prop({
    required: true
  })
  public place!: any;

  public isTipActive: boolean = false;
  public tipToggle() {
    this.isTipActive = !this.isTipActive;
  }
  public open(e) {
    const re = e.currentTarget.parentNode.parentNode;
    if (re.classList.contains('on')) {
      re.classList.remove('on');
    } else {
      re.classList.add('on');
    }
  }
  get isCustomPlace(): any {
    return Number(this.place.id) === 51034 ? true : false;
  }
}
